<template>
  <div :id="carItem.folder_name" class="car">
    <div class="car__header">
      <div class="car__header-main">
        <h2 class="car__title">{{ carItem.main_title }}</h2>
<!--        <div-->
<!--          @click="getCall({ type: 'sale', form: 'car' })"-->
<!--          class="car__content-inf car__content-inf&#45;&#45;other"-->
<!--          v-if="carItem.stock_name !== 'Solaris'"-->
<!--        >-->
<!--          <p class="car__content-inf-text">Рассрочка 0%</p>-->
<!--        </div>-->
      </div>

      <div
        v-if="carItem.availability_static"
        @click="getCall({ type: 'sale', form: 'car' })"
        class="car__content-inf"
      >
        <p class="car__content-inf-text" v-html="carItem.availability">
        </p>
      </div>

      <div
        v-else
        @click="getCall({ type: 'sale', form: 'car' })"
        class="car__content-inf"
      >
        <p v-if="carItem.folder_name !== 'mufasa'" class="car__content-inf-text">
          {{ getRandomNumber() }} авто в наличии по акции
        </p>
      </div>
    </div>

    <div class="car__content">
      <section class="car__gallery">
        <div
          :class="[
            'swiper car__slides',
            `swiper-container-${carItem.folder_name}`,
          ]"
        >
          <div class="swiper-wrapper">
            <div
              class="swiper-slide car__image-slide"
              v-for="(image, j) in carItem.gallery"
              :key="`slide-${j}`"
              @click="getSlider({ slides: carItem.gallery, activeIndex: j })"
            >
              <img class="car__slide-image" :src="image" alt="" />
            </div>
          </div>
          <div
            class="swiper-button-prev"
            :class="[`swiper-button-prev-${carItem.folder_name}`]"
          ></div>
          <div
            class="swiper-button-next"
            :class="[`swiper-button-next-${carItem.folder_name}`]"
          ></div>
        </div>
      </section>
    </div>

    <div class="car__btns-list">
      <!-- <button
        v-if="carItem.advantage"
        @click="getCall({ type: 'sale', form: 'car' })"
        class="car__btn"
      >
        <span
          >Выгода до
          <span style="white-space: nowrap"
            >{{ carItem.advantage }} ₽</span
          ></span
        >
      </button> -->
      <button
        v-if="carItem.price"
        @click="getCall({ type: 'sale', form: 'car' })"
        class="car__btn"
      >
        <span
          >Выгода до
          <span style="white-space: nowrap"
            >{{ carItem.advantage }} ₽</span
          ></span
        >
      </button>
      <button
        v-if="carItem.trade_in"
        @click="getCall({ type: 'sale', form: 'car' })"
        class="car__btn"
      >
        <span
          >Бонус за трейд-ин
          <span style="white-space: nowrap"
            >{{ carItem.trade_in }} ₽</span
          ></span
        >
      </button>

      <button
        v-if="!carItem.trade_in"
        @click="getCall({ type: 'sale', form: 'car' })"
        class="car__btn"
      >
        Уже в продаже
      </button>

      <button
        @click="getCall({ type: 'sale', form: 'car' })"
        class="car__btn car__btn_icon car__btn_security"
      >
        <span>Охранный комплекс в комплекте</span>
      </button>

      <button
        v-if="
          carItem.folder_name !== 'newCRETA' &&
          carItem.folder_name !== 'SOLARIS'
        "
        @click="getCall({ type: 'sale', form: 'car' })"
        class="car__btn car__btn_icon car__btn_gibdd"
      >
        <span>Постановка на учёт в ГИБДД в подарок</span>
      </button>

      <a
        v-if="carItem.stock_name != 'Elantra' && carItem.stock_name != 'Mufasa'"
        class="car__btn"
        @click="setStockActive(carItem.stock_name)"
        href="#stock"
      >
        Комплектации и цены
      </a>

    </div>
  </div>
</template>

<script>
import Swiper, { Navigation } from "swiper";
import Mixins from "../common/mixin";

Swiper.use([Navigation]);

export default {
  props: ["carItem"],
  emits: ["getSlider"],
  mixins: [Mixins],
  data() {
    return {
      activ_slide: this.carItem.activ_color,
      swiper: null,
      swiper2: null,
    };
  },
  mounted() {
    this.initSwiper();
  },
  methods: {
    getSlider(options) {
      this.$emit("getSlider", options);
    },
    loadAdvantagesIcon(icon_name) {
      return require(`../images/icons/${icon_name}.png`);
    },
    loadMainimg(name) {
      return require(`../images/cars/${this.carItem.folder_name}/${name}.png`);
    },

    newActivSlide(color) {
      let index = this.carItem.colors.findIndex((el) => el == color);
      this.activ_slide = this.carItem.colors[index];
      this.swiper.slideTo(index, 500);
    },

    swipModel(swip) {
      this.activ_slide = this.carItem.colors[swip.realIndex];
    },

    initSwiper() {
      this.swiper2 = new Swiper(
        `.swiper-container-${this.carItem.folder_name}`,
        {
          navigation: {
            nextEl: `.swiper-button-next-${this.carItem.folder_name}`,
            prevEl: `.swiper-button-prev-${this.carItem.folder_name}`,
          },
        }
      );
    },
    getRandomNumber() {
      return Math.round(4 + Math.random() * (9 - 4));
    },
    setStockActive(car) {
      this.$emit("setStockActive", car);
    },
  },
};
</script>

<style lang="sass" scoped>
#newCRETA, #newTUCSON
  .car__slider-item
    width: auto
    height: 100%

#mufasa .car__btns-list
  margin-top: 0

#mufasa .car__content
  margin: auto 0

.swiper-slide
  display: flex
  justify-content: center
  align-items: center

.car
    border: 1px solid rgba(0, 0, 0, 0.1)
    width: 650px
    padding: 40px
    font-family: 'Hyundai Sans Head'
    display: flex
    flex-direction: column
    margin: 0 50px 70px
    &__header
        width: 100%
        display: flex
        align-items: flex-start
        justify-content: space-between
        gap: 5px
    &__header-main
        margin-left: -30px
    &__title
        font-size: 30px
        font-weight: 500
        line-height: 37px
        background-color: rgba(228, 220, 211, 1)
        padding: 2px 10px 2px 56px
        margin-bottom: 10px
    &__gallery
      width: 100%
      overflow: hidden
    &__slides
      position: relative
    &__slides,
    &__slide-image
      max-width: 100%
      width: 100%
    &__slide-image
      cursor: pointer
    &__content
        display: flex
        flex-direction: column
        justify-content: center
        margin-bottom: 15px
        //margin-top: auto

        &-slider
          flex: 1 1 auto
        &-inf
            cursor: pointer
            &-text
                padding: 24px 5px 16px
                text-align: center
                width: 130px
                height: 130px
                font-size: 20px
                background-color: rgba(0, 44, 95, 1)
                color: #fff
                display: inline-flex
                flex-direction: column
                align-items: center
                justify-content: center

                &:hover
                  background-color: transparent
                  color: rgba(0, 44, 95, 1)
            &--other &-text
              font-weight: 600
              font-size: 18px
              width: 170px
              padding: 5px
              height: auto
    &:last-child
        //align-self: center
        justify-self: center
    &__btns-list
        width: 100%
        display: flex
        justify-content: space-between
        flex-wrap: wrap
        margin-top: auto
    &__btn
        font-size: 18px
        color: #fff
        background-color: #002c5f
        border: 1px solid #fff
        padding: 10px
        cursor: pointer
        width: 49%
        margin: 0.5%
        display: flex
        align-items: center
        justify-content: center
        text-decoration: none

        &_icon
          &::before
            content: ''
            filter: brightness(0) invert(1)
            min-width: 38px
            min-height: 38px
            margin-right: 5px
          &:hover::before
            filter: none

        &_gift::before
          background: url('../images/icons/gift.png') no-repeat center

        &_security::before
          background: url('../images/icons/security.svg') no-repeat center/contain

        &_gibdd::before
          background: url('../images/icons/gibdd.svg') no-repeat center/contain

        &:hover
          color: #002c5f
          background-color: #fff
          border-color: #002c5f

        &:last-child
          flex: 1

.car__slider
    &-item
      width: 100%
    &-paginations
        display: flex
        align-self: center
        flex-wrap: wrap
        &-item
            background-color: blue
            width: 25px
            height: 25px
            border-radius: 50%
            background-color: #2170AA
            margin: 0 5px
            cursor: pointer
            &--active
                border: 3px solid rgba(13, 74, 129, 1)

@media (max-width: 690px)
  .car
    width: 100vw
    padding: 20px
    margin: 0 0 70px

    .swiper-slide
      width: 100%

    &__slider
      width: 100%

    &__btns-list
      justify-content: space-between

    &__btn
      font-size: 15px
      padding: 8px
      width: 49%
      margin: 1% 0

      &_icon
        width: 100%

@media (max-width: 590px)
  .car
    padding-top: 0

    &__slider
      margin-bottom: 20px

    &__header
      flex-wrap: wrap

    &__header-main
      margin-left: 0
      margin-bottom: 5px

    &__title
      width: 100%
      padding: 2px 0 2px 20px
      font-size: 22px

    &__content-inf-text
      font-size: 14px
      padding: 13px 1px 11px
      width: 78px
      height: 75px
      line-height: 18px

    &__content-inf--other &__content-inf-text
      width: 162px
      padding: 10px

</style>

<template>
  <div class="stock-table">
    <div class="stock-table__body">
      <div class="stock-table__table">
        <div
          class="stock-table__row"
          v-for="car in cars"
          :key="car.name + car.compl + car.price + car.engine"
        >
          <div class="stock-table__col">
            <div class="stock-table__car-img">
              <img class="stock-table__img" :src="car.img" :alt="car.name" />
            </div>
          </div>

          <div class="stock-table__col">{{ car.name }}</div>
          <div class="stock-table__col">{{ car.compl }}</div>
          <div class="stock-table__col">{{ car.engine }}</div>

<!--          <div class="stock-table__col">-->
<!--            <button-->
<!--              class="stock-table__btn button button-dark-blue"-->
<!--              @click="$emit('call', 'complectation_price')"-->
<!--            >-->
<!--              <template v-if="car.price">-->
<!--                от <span class="line-through">{{ car.price }}</span>-->
<!--              </template>-->
<!--              <template v-else> УТОЧНИТЬ ЦЕНУ </template>-->
<!--            </button>-->
<!--          </div>-->

          <div class="stock-table__col">
            <button
              class="stock-table__btn button button-dark-blue"
              v-on:click="status = !status"
              @click="id_car = car.id"
            >
              Опции
            </button>

            <div
              v-if="car.id === id_car"
              :class="card__features"
              @click="status = !status"
            >
              <div class="card__features-list">
                <div class="card__features-close t-modal-close"></div>
                <div class="card__features-text">ОПЦИИ <br>{{ car.name }} {{ car.compl }} ({{ car.engine }})</div>
                <div
                  v-for="option in car.options"
                  class="card__features-item"
                  v-html="option"
                ></div>
              </div>
            </div>
          </div>
          <div class="stock-table__col" v-if="device_platform === 'desktop'">
            <button
              class="stock-table__btn button button-dark-blue"
              @click="$emit('call', 'complectation_bron')"
            >
              Узнать цену по акции
              <!-- Цена от {{ car.price }} -->
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="stock-table__footer">
      <button
        class="stock-table__more-button button button-dark-blue"
        @click="addCount"
        v-if="data.length > 5 && count < data.length"
      >
        Показать ещё
      </button>
    </div>
  </div>
</template>

<script>
import Mixin from "../common/mixin";

const INITIAL_COUNT = 5;
export default {
  name: "stock-table",
  props: ["data"],
  mixins: [Mixin],
  data() {
    return {
      count: INITIAL_COUNT,
      status: true,
      id_car: 0,
    };
  },
  watch: {
    data() {
      this.count = INITIAL_COUNT;
    },
  },
  computed: {
    cars() {
      return this.data.slice(0, this.count);
    },
    card__features: function () {
      if (this.status === true) {
        return "card__features disnone";
      } else {
        return "card__features disflex";
      }
    },
  },
  methods: {
    addCount() {
      this.count += 10;
    },
  },
};
</script>

<style scoped lang="scss">
@import "../styles/constants.scss";

.stock-table {
  &__table {
    padding: 29px 0;
  }
  &__img {
    width: 220px;
  }

  &__row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 7.5px 0;
    padding-left: 20px;
  }

  &__col {
    flex: 1;
    font-weight: bold;
    font-size: 16px;
    margin-right: 20px;

    .line-through {
      text-decoration: line-through;
    }
    
    &:last-child {
      flex-basis: fit-content;
      max-width: 240px;
    }

    &:nth-child(4n) {
      flex: 1.3;
    }
  }

  &__btn {
    width: 100%;
  }

  &__footer {
    display: flex;
    justify-content: center;
  }

  &__more-button {
    max-width: 226px;
  }
}

@media (max-width: 1200px) {
  .stock-table {
    &__col {
      text-align: center;

      &:last-child {
        display: none;
      }
    }
  }
}

@media (max-width: 1200px) {
}

.card__features {
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  &-text {
    text-align: center;
    margin-bottom: 10px;
  }
}
.disnone {
  display: none;
}
.disflex {
  display: flex;
}
.card__features-list {
  max-width: 518px;
  width: 100%;
  position: relative;
  padding: 40px;
  background: #fff;
  border-radius: 5px;
}
.t-modal-close {
  position: absolute;
  right: 10px;
  top: 10px;
  height: 22px;
  width: 22px;
  cursor: pointer;
  background: url("../images/utils/close.svg") no-repeat center/contain;
}
.card__features-item {
  display: flex;
  align-items: center;
  padding-top: 10px;
}
.card__features-item::before {
  content: "";
  min-width: 4px;
  min-height: 4px;
  border-radius: 50%;
  background: #001a37;
  margin-right: 5px;
}
</style>

<template>
  <section id="models" class="cars">
    <div class="container">
      <div class="cars__inner">
        <car-item
          v-bind="$attrs"
          v-for="car_item in visibleCars"
          :key="car_item.main_title"
          :carItem="car_item"
        ></car-item>
      </div>
      <button
        v-if="!showAll"
        @click="showCars"
        class="cars__button button button-dark-blue"
      >
        <span>Показать ещё</span>
      </button>
    </div>
  </section>
</template>

<script>
import CarItem from "../components/block-car.vue";
import CarsInfo from "../common/cars-info.js";
export default {
  components: { CarItem },
  data() {
    return {
      cars: CarsInfo.CARS,
      targetModels: ["newTUCSON", "SOLARIS", "newELANTRA", "newSANTAFE", "PALISADE",],
      visibleModels: 5,
      mobile: false,
      showAll: false,
    };
  },
  computed: {
    filteredCars() {
      return this.cars.filter((car) => {
        if (!this.showAll) {
          return this.mobile
            ? this.targetModels.slice(0, -1).includes(car.folder_name)
            : this.targetModels.includes(car.folder_name);
        }

        return this.cars;
      });
    },
    visibleCars() {
      return this.showAll ? this.cars : this.cars.slice(0, this.visibleModels);
    }
  },
  mounted() {
    this.mobile = window.innerWidth <= 800;
    window.addEventListener("resize", () => {
      this.mobile = window.innerWidth <= 800;
    });
  },
  methods: {
    showCars() {
      this.showAll = true;
    },
  },
};
</script>

<style lang="sass" scoped>


.cars
    padding-top: 100px
    &__inner
        margin: 0 -50px
        display: flex
        justify-content: center
        flex-wrap: wrap
    &__button
      display: block
      margin: 0 auto
      margin-bottom: 80px
@media screen and (max-width: 1431px)
  .cars__inner
    margin: 0px
</style>
